import React from "react"

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Headroom from "react-headroom"
import { VideoTag } from "react-video-tag"

import {
  bpSm,
  ButtonAnchor,
  ButtonLink,
  ContentWrapper,
  ContentWrapperDiv,
  H2,
  H3,
  Layout,
  P,
  shevy,
  theme,
  toEm,
  Wrapper,
} from "../components"
import { CampaignBanner } from "../components/blocks/CampaignBanner"
import Contact from "../components/blocks/Contact"
import Header, { HeroBlockSmall } from "../components/blocks/Header"
import HeroBlock from "../components/blocks/Hero"
import { UseInSchool } from "../components/blocks/UseInSchool"
import SEO from "../components/Seo"
import { useTranslations } from "../utils/intl"

const showUntilLg = css({
  "@media(max-width: 880px)": {
    display: "block",
  },
  display: "none",
})

const showAboveLg = css({
  "@media(max-width: 880px)": {
    display: "none",
  },
  display: "block",
})

const VideoSmall: React.FC = props => {
  return (
    <section {...props}>
      <VideoTag
        css={{
          width: "100vw",
          height: "100vw",
          objectFit: "cover",
        }}
        autoPlay={true}
        muted={true}
        playsInline={true}
        loop={true}
        src="https://d1yj9whwrn10mt.cloudfront.net/runbit.mp4"
      />
    </section>
  )
}

const Block: React.FC = props => {
  const { it } = useTranslations()
  return (
    <ContentWrapper css={{ textAlign: "center" }} {...props}>
      <H2>{it(["Outdoors 2.0", "Utomhusaktivitet 2.0"])}</H2>
      <P>
        {it([
          "Walk, Jog, Run and explore more, your city turns into a fun game!",
          "Gå, jogga, spring och upptäck mer, din stad förvandlas till ett spel!",
        ])}
        <br />
        {it([
          "Escape monster, catch animals, or collect stars",
          "Fly från monster, fånga djur eller plocka föremål.",
        ])}
      </P>
      <VideoTag
        css={{ marginTop: toEm(97), width: "100%" }}
        autoPlay={true}
        muted={true}
        playsInline={true}
        loop={true}
        src="https://d1yj9whwrn10mt.cloudfront.net/runbit.mp4"
      />
    </ContentWrapper>
  )
}

const WhoCanUse: React.FC = props => {
  const { it } = useTranslations()
  return (
    <ContentWrapper
      css={{
        display: "grid",
        gridTemplateColumns: "1.2fr 1fr",
        gridGap: toEm(48),
        [bpSm]: {
          gridTemplateColumns: "1fr",
        },
      }}
      {...props}
    >
      <div>
        <H2 css={{ marginBottom: shevy.baseSpacing() }}>
          {it(["Anyone can use Runbit", "Alla kan använda Runbit"])}
        </H2>
        {[
          {
            title: it(["Run faster", "Spring snabbare"]),
            text: it([
              `Escape monsters or catch animals while you run.`,
              `Fly från monster eller fånga djur samtidigt som du springer.`,
            ]),
            image: require("../images/run.svg").default,
          },
          {
            title: it(["More enjoyable walks", "Roligare promenader"]),
            text: it([
              `Make your walks more enjoyable by collecting items.`,
              `Gör dina promenader roligare genom att plocka föremål.`,
            ]),
            image: require("../images/a-to-b.svg").default,
          },
          {
            title: it(["Go on a treasure hunt", "Gå ut på skattjakt"]),
            text: it([
              `Bring the whole family on a treasure hunt that everyone can participate in.`,
              `Ta med hela familjen ut på en skattjakt där alla kan delta.`,
            ]),
            image: require("../images/stroller.svg").default,
          },
        ].map(({ title, text, image }) => (
          <div
            key={title}
            css={{
              display: "flex",
              ":not(last-child)": { marginBottom: toEm(48) },
            }}
          >
            <div css={{ paddingRight: toEm(32) }}>
              <img
                css={{
                  width: toEm(122),
                  [bpSm]: {
                    width: "12.8vw",
                  },
                }}
                src={image}
              />
            </div>
            <div
              css={{
                paddingTop: toEm(16),
                [bpSm]: {
                  paddingTop: "0.8vw",
                },
              }}
            >
              <H3
                css={{
                  fontSize: toEm(30),
                  marginBottom: toEm(4, 30),
                }}
              >
                {title}
              </H3>
              <p css={{ ...shevy.body }}>{text}</p>
            </div>
          </div>
        ))}
      </div>
      <div
        css={{
          justifySelf: "center",
          alignSelf: "center",
          [bpSm]: {
            display: "none",
          },
        }}
      >
        <img
          css={{ width: toEm(400) }}
          src={require("../images/walk_dog.svg").default}
        />
      </div>
    </ContentWrapper>
  )
}

const PriceTag = styled.p({
  fontSize: toEm(80),
  fontWeight: 700,
  marginBottom: toEm(24, 80),
})
const PricingTitle = styled.p({
  textTransform: "uppercase",
  fontWeight: 700,
  marginBottom: toEm(8),
})
const PricingText = styled.p({ marginBottom: toEm(24) })

const Cost: React.FC = props => {
  const { it } = useTranslations()
  return (
    <ContentWrapper css={{ textAlign: "center" }} {...props}>
      <H2>{it(["What does Runbit cost?", "Vad kostar Runbit?"])}</H2>
      <P css={{ maxWidth: toEm(564), "&&": { margin: `0 auto ${toEm(120)}` } }}>
        {it([
          "You can do any activity in the app for as long as you want.",
          "Du kan göra alla aktiviteter i appen helt kostnadsfritt.",
        ])}
      </P>
      <div
        css={{
          textAlign: "left",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: toEm(32),
          "& >div": {
            borderRadius: toEm(12),
            padding: toEm(48),
          },
        }}
      >
        <div css={{ backgroundColor: "#F6F6F6" }}>
          <PricingTitle>{it(["Free", "Gratis"])}</PricingTitle>
          <PriceTag>{it(["0 SEK", "0 kr"])}</PriceTag>
          <PricingText>
            {it(["All activities unlocked", "Alla aktiviteter upplåsta"])}
          </PricingText>
          <PricingText>
            {it(["Unlimited use", "Obegränsad användning"])}
          </PricingText>
          <PricingText>
            {it(["History saved in the cloud", "Spara historik i molnet"])}
          </PricingText>
        </div>
        <div css={{ backgroundColor: theme.colors.blue, color: "white" }}>
          <PricingTitle>{it(["One time fee", "Engångskostnad"])}</PricingTitle>
          <PriceTag>{it(["40 SEK", "40 kr"])}</PriceTag>
          <PricingText>
            {it(["All activities unlocked", "Alla aktiviteter upplåsta"])}
          </PricingText>
          <PricingText>
            {it(["Unlimited use", "Obegränsad användning"])}
          </PricingText>
          <PricingText>
            {it(["History saved in the cloud", "Spara historik i molnet"])}
          </PricingText>
          <PricingText css={{ fontWeight: 700 }}>
            {it(["No ads", "100% reklamfri"])}
          </PricingText>
        </div>
      </div>
    </ContentWrapper>
  )
}

const WorthUsing: React.FC = props => {
  const { it } = useTranslations()
  const {
    file: { childImageSharp: image },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hand-phone.png" }) {
        childImageSharp {
          gatsbyImageData(
            sizes: "100vw"
            quality: 95
            breakpoints: [380, 768, 1024, 1366, 2080]
            placeholder: TRACED_SVG
            tracedSVGOptions: { background: "#F6F6F6" }
          )
        }
      }
    }
  `)
  return (
    <section {...props}>
      <GatsbyImage
        css={{
          marginBottom: toEm(128),
        }}
        alt="Hand holding a smartphone with runbit"
        image={getImage(image)!}
      />
      <ContentWrapperDiv>
        <H2>{it(["An app worth using", "En app värd att använda"])}</H2>
        <P css={{ maxWidth: toEm(765) }}>
          {it([
            `Runbit is not just another motion tracker.
            It turns your city into a game and makes reading maps exciting.
            The app challenges your competitive instinct and combines fun with benefit.`,
            `Runbit är inte bara en träningsapp i mängden.
            Det är applikationen som förvandlar din stad till ett spel, där kartläsning aldrig varit mer spännande.
            Idén utmanar människans naturliga tävlingsinstinkt och kombinerar nytta med nöje.`,
          ])}
        </P>
        <ButtonAnchor
          href="https://d1yj9whwrn10mt.cloudfront.net/runbit/runbit-presskit.zip"
          css={{ marginTop: shevy.baseSpacing(0.5) }}
          color={theme.colors.green}
        >
          {it(["Download press kit", "Hämta presskit"])}
        </ButtonAnchor>
      </ContentWrapperDiv>
    </section>
  )
}

const Team: React.FC = props => {
  const { it } = useTranslations()
  const people = [
    {
      name: "Anders",
      desc: "UI / UX / APP",
      image: require("../images/anders.jpg").default,
    },
    {
      name: "Gosha",
      desc: "Web / Backend",
      image: require("../images/gosha.jpg").default,
    },
    {
      name: "Gustav",
      desc: "ANDROID",
      image: require("../images/gustav.jpg").default,
    },
  ]
  return (
    <ContentWrapper css={{ textAlign: "center" }} {...props}>
      <H2>QuidBit AB</H2>
      <P>
        {it([
          "Runbit is developed by QuidBit AB from Linköping, Sweden.",
          "Runbit ägs och utvecklas av QuidBit AB i Linköping.",
        ])}
      </P>
      <div
        css={{
          marginTop: toEm(64),
          display: "grid",
          justifyContent: "center",
          gridTemplateColumns: `repeat(auto-fit, ${toEm(246)})`,
          gridGap: toEm(65),
        }}
      >
        {people.map(({ name, desc, image }) => (
          <div
            key={name}
            css={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              css={{
                borderRadius: "100%",
                width: toEm(264),
                height: toEm(264),
                objectFit: "cover",
                filter: "grayscale(100%)",
                marginBottom: toEm(32),
              }}
              src={image}
            />
            <span
              css={{
                fontWeight: 700,
                fontSize: toEm(30),
                marginBottom: toEm(8, 30),
              }}
            >
              {name}
            </span>
            <span css={{ textTransform: "uppercase" }}>{desc}</span>
          </div>
        ))}
      </div>
    </ContentWrapper>
  )
}

const Index: React.FC = () => {
  const { it } = useTranslations()

  const heroRef = React.useRef<HTMLDivElement>(null)
  const [heroHeight, setHeroHeight] = React.useState<undefined | number>()

  React.useLayoutEffect(() => {
    setHeroHeight(heroRef.current?.clientHeight)
  }, [])

  return (
    <>
      <SEO
        title={it([
          "Turn your city into a fun game!",
          "Förvandla din stad till spel",
        ])}
      />
      <Wrapper>
        <Layout
          css={{
            paddingBottom: toEm(64 * 2),
            "& section": {
              marginTop: toEm(64 * 4),
              [bpSm]: {
                marginTop: toEm(64 * 2),
              },
            },
          }}
        >
          <CampaignBanner />
          <div css={showUntilLg}>
            <HeroBlockSmall />
            <VideoSmall />
          </div>
          <div css={showAboveLg}>
            <HeroBlock elementRef={heroRef} css={{ "&&": { marginTop: 0 } }} />
            <Headroom pinStart={heroHeight}>
              <Header css={{ "&&": { marginTop: 0 } }} />
            </Headroom>
            <Block />
          </div>

          <a id="about" />
          <WhoCanUse />

          <a id="schools" />
          <UseInSchool>
            <H2>{it(["Use Runbit at school!", "Använd Runbit i skolan!"])}</H2>
            <P>
              {it([
                "Do like 100+ other schools and use Runbit in teaching.",
                "Gör som 100+ st andra skolor och använd Runbit i undervisningen.",
              ])}
            </P>
            <ButtonLink to="/school" color={theme.colors.blue}>
              {it(["Read more", "Läs mer"])}
            </ButtonLink>
          </UseInSchool>

          <a id="pricing" />
          <div css={showAboveLg}>
            <Cost />
          </div>

          <WorthUsing />
          <Team />
          <a id="contact" />
          <Contact />
        </Layout>
      </Wrapper>
    </>
  )
}

export default Index
